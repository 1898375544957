import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class pickupsEdit extends React.Component {
  state = {
    baseURI: this.props.baseURI,
    recipes: [],
    pickupRecipes: [],
    recipeId: 0,
  };
  fetchRecipes() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/pickups/fetch_recipes`
    }).then((response) => { this.setState({ recipes: response.data.recipes }); });
  };
  pickupRecipes() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/pickups/fetch_pickup_recipes`
    }).then((response) => { this.setState({ pickupRecipes: response.data.recipes }); });
  };
  addPickupRecipe() {
    if (this.state.recipeId > 0) {
      const recipeIds = this.state.pickupRecipes.map(recipe => Number(recipe.id));
      if (recipeIds.indexOf(Number(this.state.recipeId)) >= 0) {
        alert('既に追加されています');
      } else {
        axios({
          method: 'POST',
          url: `${this.state.baseURI}/api/v1/pickups`,
          params: {
            recipe_id: this.state.recipeId,
          }
        }).then(() => {
          this.pickupRecipes();
          alert('レシピを追加しました');
        });
      }
    } else {
      alert('レシピを選択してください');
    }
  };
  removePickupRecipe(e) {
    const recipeId = e.currentTarget.getAttribute('data-id');
    if (confirm('このレシピを削除しますか？')) {
      axios({
        method: 'DELETE',
        url: `${this.state.baseURI}/api/v1/pickups`,
        params: {
          recipe_id: recipeId,
        }
      }).then(() => {
        this.pickupRecipes();
        alert('レシピを削除しました');
      });
    }
  };
  changeRecipe(e) {
    this.setState({ recipeId: e.currentTarget.value });
  }
  componentDidMount() {
    this.fetchRecipes();
    this.pickupRecipes();
  };
  render() {
    let recipes = this.state.recipes.map(recipe => <option value={recipe.id} key={recipe.id}>{recipe.title}</option>);

    let pickupRecipes = this.state.pickupRecipes.map(recipe => <div className="news__card" key={recipe.id}>
      <div className="flex items-center pd10">
        <h2>{recipe.title}</h2>
        <div className="ml-auto pointer" data-id={recipe.id} onClick={this.removePickupRecipe.bind(this)}><i className="fas fa-times"></i></div>
      </div>
    </div>);

    return <div className="mt20">
      <div className="flex items-center">
        <select className="selector" onChange={this.changeRecipe.bind(this)}>
          <option disabled selected>レシピを選択してください</option>
          {recipes}
        </select>
        <button className="ml10 selectBtn" onClick={this.addPickupRecipe.bind(this)}>追加</button>
      </div>

      <div className="news__container mt20">
        <div className="news__wrapper">
          {pickupRecipes}
      </div>
      </div>
    </div>;
  }
}

pickupsEdit.propTypes = {
  baseURI: PropTypes.string,
};
export default pickupsEdit
