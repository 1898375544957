import React from "react"
import PropTypes from "prop-types"
import Ingredients from './ingredients.js';
import CookingStyles from './cookingStyles.js';
import Genres from './genres.js';

class Tags extends React.Component {
  render() {
    return <div>
      <Ingredients
        baseURI={this.props.baseURI}
        publicId={this.props.publicId}
        selectIngredientIds={this.props.selectIngredientIds}
      />
      <CookingStyles
        baseURI={this.props.baseURI}
        publicId={this.props.publicId}
        selectCookingStyleIds={this.props.selectCookingStyleIds}
      />
      <Genres
        baseURI={this.props.baseURI}
        publicId={this.props.publicId}
        selectGenreIds={this.props.selectGenreIds}
      />
    </div>;
  }
}

Tags.propTypes = {
  baseURI: PropTypes.string,
  publicId: PropTypes.string,
  selectIngredientIds: PropTypes.array,
  selectCookingStyleIds: PropTypes.array,
  selectGenreIds: PropTypes.array,
};
export default Tags
