import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class Genres extends React.Component {
  state = {
    isLoading: false,
    baseURI: this.props.baseURI,
    genres: [],
    selectIds: this.props.selectGenreIds,
    successMessage: '',
  };
  toggleTags(e) {
    this.setState({ successMessage: '' });
    let selectIds = this.state.selectIds
    let targetNumber = Number(e.currentTarget.dataset.id);
    if (e.currentTarget.checked) {
      selectIds.push(targetNumber);
    } else {
      selectIds = selectIds.filter(n => n !== targetNumber);
    }
    this.setState({ selectIds: selectIds });
  };
  fetchGenres() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/recipes/tags/fetch`,
      params: {
        public_id: this.props.publicId,
        tag_type: 'genre',
      }
    }).then((response) => { this.setState({ genres: response.data.tags }); });
  };
  postTag() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      axios({
        method: 'POST',
        url: `${this.state.baseURI}/api/v1/recipes/tags`,
        params: {
          public_id: this.props.publicId,
          select_ids: this.state.selectIds,
          tag_id: 'genre',
        }
      }).then(() => {
        this.setState({ isLoading: false, successMessage: 'タグ（ジャンル）を更新しました' });
      });
    } else {
      alert('登録処理中です');
    }
  };
  componentDidMount() {
    this.fetchGenres();
  };
  render() {
    let genres = this.state.genres.map((ingredient) => <div className="tags__item" key={ingredient.id}>
      <input
        type="checkbox"
        id={`ing_${ingredient.id}`}
        defaultChecked={this.state.selectIds.indexOf(ingredient.id) >= 0}
        onChange={this.toggleTags.bind(this)}
        data-id={ingredient.id}
      />
      <label htmlFor={`ing_${ingredient.id}`}>{ingredient.name}</label>
    </div>);

    let registerButton = <div className={`mt20 defaultBtn ${this.state.isLoading ? 'loading' : ''}`} onClick={this.postTag.bind(this)}><p className="color-white loader">登録</p></div>

    return <div>
      <div className="flex mt40 items-center flex">
        <label className="form-title-independence mr10">ジャンル</label>
        <p className="ml10 color-red"><small>{this.state.successMessage}</small></p>
        <a href="/admin/tags/new?tag_type=genre" className="ml-auto link-button">タグを追加する</a>
      </div>
      <div className="tags">
        {genres}
      </div>
      {registerButton}
    </div>;
  }
}

Genres.propTypes = {
  baseURI: PropTypes.string,
  publicId: PropTypes.string,
  selectGenreIds: PropTypes.array,
};
export default Genres
