import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class recipesNew extends React.Component {
  state = {
    baseURI: this.props.baseURI,
    title: this.props.recipe.title,
    description: this.props.recipe.description,
    publicId: this.props.recipe.public_id,
  };

  changeTitle(e) {
    this.setState({ title: e.currentTarget.value });
  };

  changeDescription(e) {
    this.setState({ description: e.currentTarget.value });
  };

  create() {
    axios({
      method: 'POST',
      url: `${this.state.baseURI}/api/v1/recipes`,
      params: {
        title: this.state.title,
        description: this.state.description,
        public_id: this.state.publicId,
      }
    }).then((res) => {
      window.location.href = res.data.url;
    }).catch(function (error) {
      alert(error.response.data.message);
    });
  };

  render() {
    return <div className="width-100">
      <div className="field mt40 flex">
        <label className="form-title">料理名</label>
        <input type="text" className="form-input" placeholder="料理名" defaultValue={this.props.recipe.title} onChange={this.changeTitle.bind(this)}/>
      </div>

      <div className="flex mt40 items-center">
        <label className="form-title-independence mr10">料理の説明</label>
      </div>
      <textarea className="form-text-area mt10" placeholder="料理の説明" style={{ minHeight: '8em' }} defaultValue={this.props.recipe.description} onChange={this.changeDescription.bind(this)}></textarea>

      <div className="actions mt40 mb20">
        <div className="recipes__step mb20">
          <p className="current text-center width-100">次の項目：材料を登録する</p>
        </div>
        <div className="defaultBtn" onClick={this.create.bind(this)}>次へ</div>
      </div>
    </div>;
  }
}

recipesNew.propTypes = {
  baseURI: PropTypes.string,
  recipe: PropTypes.object,
};
export default recipesNew
