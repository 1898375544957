import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class Products extends React.Component {
  state = {
    isLoading: false,
    baseURI: this.props.baseURI,
    products: this.props.products,
    selectProducts: this.props.selectProducts,
    successMessage: '',
  };
  postProduct() {
    if (!this.state.isLoading) {
      this.setState({ isLoading: true });
      axios({
        method: 'POST',
        url: `${this.state.baseURI}/api/v1/recipes/products`,
        params: {
          public_id: this.props.publicId,
          products: this.state.selectProducts,
        }
      }).then(() => {
        this.setState({ isLoading: false, successMessage: '使用商品を更新しました' });
      });
    } else {
      alert('登録処理中です');
    }
  };
  toggleProducts(e) {
    this.setState({ successMessage: '' });
    const productId = Number(e.currentTarget.dataset.id);
    const product = { product_id: productId, quantity: '' };
    let products = this.state.selectProducts;
    products = products.filter(product => product.product_id != productId);

    if (e.currentTarget.checked) {
      products.push(product);
    } else {
      products = products.filter(product => product.product_id !== productId);
    }

    this.setState({ selectProducts: products });
  };
  changeQuantity(e) {
    const quantity = e.currentTarget.value;
    const productId = Number(e.currentTarget.dataset.id);
    const product = { product_id: productId, quantity: quantity };
    let products = this.state.selectProducts;
    products = products.filter(product => product.product_id != productId);
    products.push(product);

    this.setState({ selectProducts: products });
  };
  render() {
    let selectProducts = this.state.selectProducts;
    let products = this.state.products.map((product) => <div className="products__tag" key={product.id}>
      <input type="checkbox"
        id={product.slug}
        defaultChecked={this.state.selectProducts.map(product => product.product_id).indexOf(product.id) >= 0}
        onChange={this.toggleProducts.bind(this)}
        data-id={product.id}
      />
      <label htmlFor={product.slug}>{product.name}</label>
      {this.state.selectProducts.map(product => product.product_id).indexOf(product.id) >= 0 ? <div className="mt10 ingredients-details"><div className="detail"><div className="detail__item">
        <label>量</label>
        <input
          type="text"
          onChange={this.changeQuantity.bind(this)}
          placeholder="適量"
          defaultValue={selectProducts.filter(p => p.product_id === product.id)[0].quantity}
          data-id={product.id}
        ></input>
      </div></div></div> : ''}
    </div>);

    let registerButton = <div className={`mt20 defaultBtn ${this.state.isLoading ? 'loading' : ''}`} onClick={this.postProduct.bind(this)}><p className="color-white loader">登録</p></div>


    return <div>
      <div className="mt40 items-center flex">
        <label className="form-title-independence">使用商品</label>
        <p className="ml10 color-red"><small>{this.state.successMessage}</small></p>
      </div>
      <div className="products">
        {products}
        {registerButton}
      </div>
    </div>;
  }
}

Products.propTypes = {
  baseURI: PropTypes.string,
  publicId: PropTypes.string,
  products: PropTypes.array,
  selectIds: PropTypes.array,
  selectProducts: PropTypes.array,
};
export default Products
