import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class topProducts extends React.Component {
  state = {
    baseURI: this.props.baseURI,
    products: [],
    packages: [],
    topProducts: [],
    productId: 0,
    packageId: 0,
  };
  fetchProducts() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/top/products/fetch_products`
    }).then((response) => { this.setState({ products: response.data.products }); });
  };
  fetchPackages() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/top/products/fetch_packages`
    }).then((response) => { this.setState({ packages: response.data.packages }); });
  };
  fetchTopProducts() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/top/products/top_products`
    }).then((response) => { this.setState({ topProducts: response.data.top_products }); });
  };
  addProduct() {
    this.addTopProduct(this.state.productId, 'product');
  };
  addPackage() {
    this.addTopProduct(this.state.packageId, 'package');
  };
  addTopProduct(id, type) {
    if (id > 0) {
      const productIds = this.state.topProducts.map(product => product.product ? Number(product.product.id) : '').filter(v => v);
      const packageIds = this.state.topProducts.map(product => product.package ? Number(product.package.id) : '').filter(v => v);
      if (type == 'product' && productIds.indexOf(Number(this.state.productId)) >= 0) {
        alert('商品が既に追加されています');
      } else if (type == 'package' && packageIds.indexOf(Number(this.state.packageId)) >= 0) {
        alert('パッケージ商品が既に追加されています');
      } else {
        axios({
          method: 'POST',
          url: `${this.state.baseURI}/api/v1/top/products`,
          params: {
            send_id: type == 'product' ? this.state.productId : this.state.packageId,
            id_type: type
          }
        }).then(() => {
          this.fetchTopProducts();
          alert('掲載商品を追加しました');
        });
      }
    } else {
      alert('商品を選択してください');
    }
  };
  removeTopProduct(e) {
    const id = e.currentTarget.getAttribute('data-id');
    if (confirm('この商品を削除しますか？')) {
      axios({
        method: 'DELETE',
        url: `${this.state.baseURI}/api/v1/top/products`,
        params: {
          id: id,
        }
      }).then(() => {
        this.fetchTopProducts();
        alert('商品を削除しました');
      });
    }
  };
  changeProduct(e) {
    this.setState({ productId: e.currentTarget.value });
  };
  changePackage(e) {
    this.setState({ packageId: e.currentTarget.value });
  };
  componentDidMount() {
    this.fetchProducts();
    this.fetchPackages();
    this.fetchTopProducts();
  };
  render() {
    let products = this.state.products.map(product => <option value={product.id} key={product.id}>{product.name}</option>);
    let packages = this.state.packages.map(p => <option value={p.id} key={p.id}>{p.name}</option>);

    let topProducts = this.state.topProducts.map(product => <div className="news__card" key={product.id}>
      <div className="flex items-center pd10">
        <h2>{product.product ? product.product.name : product.package.name}</h2>
        <div className="ml-auto pointer" data-id={product.id} onClick={this.removeTopProduct.bind(this)}><i className="fas fa-times"></i></div>
      </div>
    </div>);

    return <div className="mt20">
      <div className="flex items-center">
        <div className="flex">
          <select className="selector" onChange={this.changeProduct.bind(this)}>
            <option disabled selected>商品を選択してください</option>
            {products}
          </select>
          <button className="ml10 selectBtn" onClick={this.addProduct.bind(this)}>追加</button>
          <select className="ml20 selector" onChange={this.changePackage.bind(this)}>
            <option disabled selected>パッケージ商品を選択してください</option>
            {packages}
          </select>
          <button className="ml10 selectBtn" onClick={this.addPackage.bind(this)}>追加</button>
        </div>
      </div>

      <div className="news__container mt20">
        <div className="news__wrapper">
          {topProducts}
        </div>
      </div>
    </div>;
  }
}

topProducts.propTypes = {
  baseURI: PropTypes.string,
};
export default topProducts
