import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class preparations extends React.Component {
  state = {
    isLoading: true,
    baseURI: this.props.baseURI,
    preparations: ['', ''],
  };
  fetchPreparations() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/recipes/fetch_preparations`,
      params: {
        public_id: this.props.publicId,
      }
    }).then((response) => {
      this.setState({
        isLoading: false,
        preparations: response.data.preparations
      });
    });
  };
  addPreparations() {
    preparations = this.state.preparations;
    preparations.push('');
    this.setState({ preparations: preparations });
  };
  removePreparations() {
    preparations = this.state.preparations;
    preparations.pop();
    this.setState({ preparations: preparations });
  }
  componentDidMount() {
    this.fetchPreparations();
  };
  render() {
    let preparations = '';
    if (this.state.isLoading) {
      preparations = <div className="">データを読み込んでいます<img src="https://d3risj25c02mqp.cloudfront.net/material/icon/loading.gif" alt="ローダー" style={{ width: '20px' }} /></div>;
    } else {
      preparations = this.state.preparations.map((preparation, key) => <div className="detail" key={key}>
        <div className="detail__item"><label>{key + 1}</label><textarea id={`preparations[][content]`} name={`preparations[][content]`} placeholder="お魚は刺身用を使用する。" defaultValue={preparation.content}></textarea></div>
      </div>);
    }


    return <div>
      <div className="flex mt40 items-center">
        <label className="form-title-independence mr10">下準備</label>
        <div className="plus-minus-button">
          <div onClick={this.addPreparations.bind(this)}><i className="fas fa-plus"></i></div>
          <div onClick={this.removePreparations.bind(this)}><i className="fas fa-minus"></i></div>
        </div>
      </div>
      <div className="ingredients-details mt20">{preparations}</div>
    </div>;
  }
}

preparations.propTypes = {
  baseURI: PropTypes.string,
  public_id: PropTypes.string,
};
export default preparations
