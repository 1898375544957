import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class ingredients extends React.Component {
  state = {
    isLoading: true,
    baseURI: this.props.baseURI,
    ingredients: [{ name: '', quantity: '' }, { name: '', quantity: '' }, { name: '', quantity: '' }],
  };
  fetchIngredients() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/recipes/fetch_ingredients`,
      params: {
        public_id: this.props.publicId
      }
    }).then((response) => {
      this.setState({
        isLoading: false,
        ingredients: response.data.ingredients
      });
    });
  };
  addIngredients() {
    ingredients = this.state.ingredients;
    ingredients.push({ name: '', quantity: '' });
    this.setState({ ingredients: ingredients });
  };
  removeIngredients() {
    ingredients = this.state.ingredients;
    ingredients.pop();
    this.setState({ ingredients: ingredients });
  }
  componentDidMount() {
    this.fetchIngredients();
  };
  render() {

    let ingredients = '';
    if (this.state.isLoading) {
      ingredients = <div className="">データを読み込んでいます<img src="https://d3risj25c02mqp.cloudfront.net/material/icon/loading.gif" alt="ローダー" style={{ width: '20px' }} /></div>;
    } else {
      ingredients = this.state.ingredients.map((ingredient, key) => <div className="detail" key={key}>
        <div className="detail__item"><label>材料名</label><input type="text" id={`ingredient[][name]`} name={`ingredient[][name]`} placeholder="ご飯" defaultValue={ingredient.name} /></div>
        <div className="detail__item"><label>量</label><input type="text" id={`ingredient[][quantity]`} name={`ingredient[][quantity]`} placeholder="200g程度" defaultValue={ingredient.quantity} /></div>
      </div>);
    }


    return <div>
      <div className="flex mt40 items-center">
        <label className="form-title-independence mr10">材料</label>
        <div className="plus-minus-button">
          <div onClick={this.addIngredients.bind(this)}><i className="fas fa-plus"></i></div>
          <div onClick={this.removeIngredients.bind(this)}><i className="fas fa-minus"></i></div>
        </div>
      </div>
      <div className="ingredients-details mt20">{ingredients}</div>
    </div>;
  }
}

ingredients.propTypes = {
  baseURI: PropTypes.string,
  publicId: PropTypes.string,
};
export default ingredients
