import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class contacts extends React.Component {
  state = {
    baseURI: this.props.baseURI,
    isSubmit: false,
    isApproval: false,
    name: '',
    email: '',
    tel: '',
    company: '',
    content: '',
    errorEmail: false
  };
  componentDidMount() {
  };
  changeName(e) {
    this.setState({
      name: e.target.value
    });
  };
  changeEmail(e) {
    this.setState({
      email: e.target.value
    });
  };
  changeTel(e) {
    this.setState({
      tel: e.target.value
    });
  };
  changeCompany(e) {
    this.setState({
      company: e.target.value
    });
  };
  changeContent(e) {
    this.setState({
      content: e.target.value
    });
  };
  regexEmail(e) {
    var reg = new RegExp(/[\w\d_-]+@[\w\d_-]+\.[\w\d._-]+/g);
    if (reg.test(e.target.value)) {
      this.setState({
        errorEmail: false
      });
    } else {
      this.setState({
        errorEmail: true
      });
    }
  };
  onClickApproval() {
    this.setState({
      isApproval: this.state.isApproval ? false : true
    });
  };
  onSubmit() {
    if (this.state.isApproval && this.state.name.length > 0 && this.state.email.length > 4 && this.state.content.length > 0) {
      this.setState({
        isSubmit: true
      });
      axios({
        method: 'POST',
        url: `${this.state.baseURI}/api/v1/contacts`,
        params: {
          name: this.state.name,
          email: this.state.email,
          tel: this.state.tel,
          company: this.state.company,
          content: this.state.content
        }
      }).then(response => {
        this.setState({
          isSubmit: false,
          isApproval: false,
          name: '',
          email: '',
          tel: '',
          company: '',
          content: ''
        });
        alert('お問い合わせの送信が完了しました');
      }).catch(error => {
        alert('送信に失敗しました');
      });
    } else if (this.state.errorEmail) {
      alert('正しく入力されていないフォームがあります');
    } else if (this.state.name.length < 1 || this.state.email.length < 5 || this.state.tel.length < 1 || this.state.content.length < 1) {
      alert('必須項目を入力してください');
    } else {
      alert('個人情報保護方針に同意してください。');
    }
  };

  render() {
    let check = this.state.isApproval ? <i className="fas fa-check"></i> : '';
    let submitClass = this.state.isApproval ? "defaultBtn" : "unSubmitButton";
    let submitButton = this.state.isSubmit ? <div className="unSubmitButton" id="submitButton" onClick={this.onSubmit.bind(this)}>送信中&nbsp;<i className="is-loading fas fa-spinner"></i><span className="ml20 dsp-inline"></span></div> : <div className={submitClass} id="submitButton" onClick={this.onSubmit.bind(this)}>送信する<span className="ml20 dsp-inline"><i className="fas fa-chevron-right"></i></span></div>;

    let errorEmailMessage = this.state.errorEmail ? <div className="form-error"><p>⚠メールアドレスを正しく入力してください</p></div> : '';

    var contactForm = <div className="contact-form">
      <div className="form">
        <p><span className="form-title">お名前</span><span className="required">必須</span></p>
        <input type="text" placeholder="例）淡口 太郎" onChange={this.changeName.bind(this)} value={this.state.name} required />
      </div>
      <div className="form">
        <p><span className="form-title">メールアドレス</span><span className="required">必須</span></p>
        <input type="email" placeholder="example@usukuchi.com" onChange={this.changeEmail.bind(this)} onBlur={this.regexEmail.bind(this)} value={this.state.email} required />
      </div>
      {errorEmailMessage}
      <div className="form">
        <p><span className="form-title">電話番号</span></p>
        <input type="tel" placeholder="例）000-0000-0000" onChange={this.changeTel.bind(this)} value={this.state.tel} />
      </div>
      <div className="form">
        <p><span className="form-title">御社名</span></p>
        <input type="text" placeholder="例）うすくち文化研究所" onChange={this.changeCompany.bind(this)} value={this.state.company} />
      </div>
      <div className="form-textarea">
        <p><span className="form-title">お問い合わせ内容<br />ご意見・ご感想</span><span className="required">必須</span></p>
        <textarea onChange={this.changeContent.bind(this)} value={this.state.content}></textarea>
      </div>
      <div className="contact-box">
        <p className="bold">個人情報の取扱いについて</p>
        <p className="mt10">うすくち文化研究所では、当サイトのご利用に際し、よりよいサービスのご提供を続けるため、個人情報を収集することがございます。<br />
          収集された個人情報については,業務運営に関する事項にのみ使用し、当社が責任を持って管理致します。<br />
          また、利用者ご本人への事前の許可なしに、むやみに第三者へ個人情報を開示することはいたしません。</p>
        <div className="approval-label" onClick={this.onClickApproval.bind(this)}>
          <div className="checkbox">{check}</div>
          個人情報保護方針に同意の上、送信します。
        </div>
        {submitButton}
      </div>
    </div>;

    return (
      contactForm
    );
  }
}

contacts.propTypes = {
  baseURI: PropTypes.string,
};
export default contacts
