import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class advices extends React.Component {
  state = {
    isLoading: true,
    baseURI: this.props.baseURI,
    advices: ['', ''],
  };
  fetchAdvices() {
    axios({
      method: 'GET',
      url: `${this.state.baseURI}/api/v1/recipes/fetch_advices`,
      params: {
        public_id: this.props.publicId,
      }
    }).then((response) => {
      this.setState({
        isLoading: false,
        advices: response.data.advices
      });
    });
  };
  addAdvices() {
    advices = this.state.advices;
    advices.push('');
    this.setState({ advices: advices });
  };
  removeAdvices() {
    advices = this.state.advices;
    advices.pop();
    this.setState({ advices: advices });
  }
  componentDidMount() {
    this.fetchAdvices();
  };
  render() {
    let advices = '';
    if (this.state.isLoading) {
      advices = <div className="">データを読み込んでいます<img src="https://d3risj25c02mqp.cloudfront.net/material/icon/loading.gif" alt="ローダー" style={{ width: '20px' }} /></div>;
    } else {
      advices = this.state.advices.map((advice, key) => <div className="detail" key={key}>
        <div className="detail__item"><label>{key + 1}</label><textarea id={`advices[][content]`} name={`advices[][content]`} placeholder="万能醤油は、魚100gに大さじ1が目安！" defaultValue={advice.content}></textarea></div>
      </div>);
    }


    return <div>
      <div className="flex mt40 items-center">
        <label className="form-title-independence mr10">ワンポイント<br />アドバイス</label>
        <div className="plus-minus-button">
          <div onClick={this.addAdvices.bind(this)}><i className="fas fa-plus"></i></div>
          <div onClick={this.removeAdvices.bind(this)}><i className="fas fa-minus"></i></div>
        </div>
      </div>
      <div className="ingredients-details mt20">{advices}</div>
    </div>;
  }
}

advices.propTypes = {
  baseURI: PropTypes.string,
  publicId: PropTypes.string,
};
export default advices
